<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">งานเมื่อถึงคลินิก</h4>
      </div>
      <v-data-table
        :headers="headers"
        :items="dataMockupArrive"
        :loading="loadarrivedata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">
            <v-checkbox
              v-model="item.work_status_bool"
              readonly
              @click="checkArriveItem(item)"
            ></v-checkbox>
          </td>

          <td class="text-center">{{item.work_name}}</td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewArriveItem(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="fetchArriveData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>

      <div class="page-title-box d-flex align-items-center justify-content-between mt-5">
        <h4 class="mb-0">งานเมื่อจบวัน</h4>
      </div>
      <v-data-table
        :headers="headers"
        :items="dataMockupFinish"
        :loading="loadfinishdata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">
            <v-checkbox
              v-model="item.work_status_bool"
              readonly
            ></v-checkbox>
          </td>

          <td class="text-center">{{item.work_name}}</td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewFinishItem(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="fetchFinishData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogView" max-width="640px">
      <v-card>
        <v-card-title>
          รายละเอียดงาน
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <span class="label-drudsandmedical-add-stock-incard">{{`วันที่ : ${moment().add(543, 'year').format('DD/MM/YYYY')}`}}</span>
          <span class="label-drudsandmedical-add-stock-incard">{{`ชื่องาน : ${dialogData.work_name}`}}</span>
          <span class="label-drudsandmedical-add-stock-incard">{{`รหัสงาน : ${dialogData.work_code}`}}</span>
          <span class="label-drudsandmedical-add-stock-incard">{{`กลุ่มงาน : ${dialogData.work_group_name}`}}</span>
          <div  class="d-flex flex-row">
            <span class="label-drudsandmedical-add-stock-incard">{{`ประเภท : ${dialogData.work_category}`}}</span>
            <v-icon class="mr-2">{{dialogData.work_type === 'drug' ? 'mdi-pill' : 'mdi-needle'}}</v-icon>
          </div>
          <span class="label-drudsandmedical-add-stock-incard">{{`สถานะงาน : ${dialogData.work_status === 'waiting' ? 'กำลังรอ' : dialogData.work_status === 'done' ? 'เสร็จสิ้น' : dialogData.work_status}`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDialog"
          >
            ปิด
          </v-btn>
          <v-btn
            color="success"
            text
            @click="gotosavedetail"
          >
            ลงบันทึกเคส
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCheck"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title>
          ต้องการเสร็จสิ้นงานนี้ใช่หรือไม่?
        </v-card-title>
        <v-card-text>{{`ชื่องาน ${dialogData.work_name}`}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialogCheck = false"
          >
            ปิด
          </v-btn>
          <v-btn
            color="error darken-1"
            text
            @click="dialogCheck = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="dialogCheck = false"
          >
            เสร็จสิ้น
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loader: null,
      loadarrivedata: false,
      loadfinishdata: false,
      dialogView: false,
      dialogCheck: false,
      dialogData: {},
      dataMockupArrive: [
        {
          _id: '0001',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0002',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0003',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0004',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0005',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0006',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0007',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0008',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0009',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'waiting',
          work_status_bool: false
        },
        {
          _id: '0010',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        }
      ],
      dataMockupFinish: [
        {
          _id: '0001',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0002',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0003',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0004',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0005',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0006',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0007',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0008',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0009',
          work_type: 'drug',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        },
        {
          _id: '0010',
          work_type: 'medical',
          work_code: 'M100001',
          work_name: 'Botox กราม (0.7)',
          work_group_name: 'Botox',
          work_category: 'หน้า',
          work_status: 'done',
          work_status_bool: true
        }
      ]
    }
  },
  methods: {
    clearDialogItem () {
      const self = this
      self.dialogData = {}
    },
    checkArriveItem (item) {
      const self = this
      self.dialogCheck = true
      self.dialogData = item
    },
    viewArriveItem (item) {
      const self = this
      self.dialogView = true
      self.dialogData = item
    },
    viewFinishItem (item) {
      const self = this
      self.dialogView = true
      self.dialogData = item
    },
    closeDialog () {
      const self = this
      self.dialogView = false
    },
    gotosavedetail () {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'CaseDetailScreen', params: { customerItems: self.dialogData } })
      }, 200)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '',
          align: 'center',
          value: 'work_status_bool',
          sortable: false
        }, {
          text: 'ชื่องาน',
          align: 'center',
          value: 'work_name',
          sortable: false
        }, {
          text: '',
          align: 'center',
          value: 'work_status_bool',
          sortable: false
        }
      ]
    }
  }
}
</script>
